import { Permission } from 'src/services/auth/permissions';
import { UserLegalEntity } from 'src/types';

export enum ContractorUserRole {
  CONTRACTOR = 'Contractor',
  CONTRACTOR_ASSISTANT = 'Contractor Assistant',
  FOREMAN = 'Foreman',
  WORKER = 'Worker',
}

export enum GeneralContractorUserRole {
  GENERAL_CONTRACTOR = 'General Contractor',
  GENERAL_CONTRACTOR_ASSISTANT = 'General Contractor Assistant',
}

export enum UserRole {
  CONTRACTOR = 'Contractor',
  CONTRACTOR_ASSISTANT = 'Contractor Assistant',
  FOREMAN = 'Foreman',
  GENERAL_CONTRACTOR = 'General Contractor',
  GENERAL_CONTRACTOR_ASSISTANT = 'General Contractor Assistant',
  INVESTOR = 'Investor',
  INVESTOR_ASSISTANT = 'Investor Assistant',
  WORKER = 'Worker',
}

// The default user type
export interface CurrentUser {
  avatar?: null | string;
  email: string;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  legalEntity: UserLegalEntity;
  permissions: Permission[];
  phoneLandline: string;
  phoneMobile: string;
  roles: UserRole[];
}

export interface User {
  avatar?: null | string;
  firstName?: string;
  fullName?: string;
  id: number;
  lastName?: string;
  legalEntity: UserLegalEntity;
}

// Used to display users of legal entity in employees list
export interface LegalEntityUser {
  avatar?: null | string;
  email: string;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  legalEntity: UserLegalEntity;
  personnelNumber: string;
  phoneLandline: string;
  phoneMobile: string;
  roles: UserRole[];
  vacationDays: number;
  dailyWorkingTime: number;
  workDays: string[];
}

export interface TimeBookingUser extends Omit<LegalEntityUser, 'phoneLandline' | 'phoneMobile'> {}
